<template>
    <div>

        <div class="row" style="min-height: 100vh; width: 100%;background: #E9ecef;">

            <!-- col 1 -->
            <div class="col-12 col-lg-3" style="background-color: #343a40;color:#CCCCCC;padding: 25px 40px;"
                data-aos-once="true" data-aos="slide-left" data-aos-duration="1500">

                <div class="text-center white--text">
                    <v-avatar size="100">
                        <img :src="$store.state.image" class="img-fluid" />
                    </v-avatar>

                    <h4 class="my-3 font-weight-bold">{{ $store.state.ye.fullname }}</h4>
                    <h6 style="color:#FF5E21">{{ $store.state.ye.email }}</h6>
                </div>

                <v-divider></v-divider>

                <div>
                    <h5>Phone Number: </h5>
                    <h6>{{ $store.state.ye.phone }}</h6>
                </div>

                <v-divider></v-divider>

                <div>
                    <h6>What year currently in: </h6>
                    <h6>{{ $store.state.ye.year_currently_in }}</h6>
                </div>

                <v-divider></v-divider>

                <div>
                    <h6>Will Graduate in : </h6>
                    <h6>{{ $store.state.ye.graduation_year }}</h6>
                </div>

                <v-divider></v-divider>

                <div>
                    <h6>Favorite Classe: </h6>
                    <h6>{{ $store.state.ye.favorite_class }}</h6>
                </div>

            </div>

            <!-- col 2 -->
            <div class="grey lighten-3 col-12 col-lg-6" data-aos="fade-up-right" data-aos-duration="1500"
                data-aos-once="true" style="padding: 50px 40px;">

                <div class="row d-flex justify-center">

                    <div class="col-12 text-center p-0">
                        <h3 class="font-weight-bold">{{ timeString }}</h3>
                    </div>

                    <div class="pb-5 col-12 text-center">
                        <v-btn-toggle v-model="part" mandatory color="orange">
                            <v-btn sdisabled="true">
                                <h4>1</h4>
                            </v-btn>
                            <v-btn sdisabled="true">
                                <h4>2</h4>
                            </v-btn>
                            <v-btn sdisabled="true">
                                <h4>3</h4>
                            </v-btn>
                            <v-btn sdisabled="true">
                                <h4>4</h4>
                            </v-btn>
                            <v-btn sdisabled="true">
                                <h4>5</h4>
                            </v-btn>
                        </v-btn-toggle>

                        <v-divider></v-divider>
                    </div>

                    <!-- part 0 -->
                    <div class="col-12 text-center" v-if="part==0">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Narrative Composition:</span><br />
                                    Imagine you are stranded on a deserted island. Describe your experience, including
                                    how you got there, your emotions, and your strategies for survival. Consider
                                    elements such as setting, character development, and plot progression. Your response
                                    should be no less than 250 words.
                                </h1>
                                <textarea onpaste="return false;" spellcheck="false" class="form-control text-h p-3"
                                    placeholder="Your answer here" style="margin: 10px 0px;" v-model="test.number1"
                                    rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number1<250">
                                    {{words.number1}}{{ words.number1 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number1}}{{ words.number1 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 1 -->
                    <div class="col-12 text-center" v-if="part==1">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Analytical Essay:
                                    </span><br />
                                    Analyze the theme of identity in a novel or film of your choice. Discuss how the
                                    characters' identities shape the plot and contribute to the overall message of the
                                    work. Provide specific examples and use critical analysis to support your points.
                                    Your response should be no less than 250 words.

                                </h1>
                                <textarea onpaste="return false;" spellcheck="false" class="form-control text-h p-3"
                                    placeholder="Your answer here" style="margin: 10px 0px;" v-model="test.number2"
                                    rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number2<250">
                                    {{words.number2}}{{ words.number2 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number2}}{{ words.number2 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 2 -->
                    <div class="col-12 text-center" v-if="part==2">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Argumentative Essay:
                                    </span><br />
                                    Is technology more of a blessing or a curse in modern society? Take a stance and
                                    support your argument with evidence from various sources, including personal
                                    experience, statistics, and expert opinions. Address both the positive and negative
                                    impacts of technology on individuals and society as a whole. Your response should be
                                    no less than 250 words.
                                </h1>
                                <textarea onpaste="return false;" spellcheck="false" class="form-control text-h p-3"
                                    placeholder="Your answer here" style="margin: 10px 0px;" v-model="test.number3"
                                    rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number3<250">
                                    {{words.number3}}{{ words.number3 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number3}}{{ words.number3 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 3 -->
                    <div class="col-12 text-center" v-if="part==3">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Literary Analysis:
                                    </span><br />
                                    Explore the symbolism of light and darkness in a poem or short story of your choice.
                                    Discuss how these elements contribute to the overall meaning and themes of the work.
                                    Consider the contrast between light and darkness, as well as any shifts in their
                                    significance throughout the text. Your response should be no less than 250 words.
                                </h1>
                                <textarea onpaste="return false;" spellcheck="false" class="form-control text-h p-3"
                                    placeholder="Your answer here" style="margin: 10px 0px;" v-model="test.number4"
                                    rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number4<250">
                                    {{words.number4}}{{ words.number4 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number4}}{{ words.number4 <= 1?' word' : ' words' }}</h4>

                            </div>
                        </form>
                    </div>

                    <!-- part 4 -->
                    <div class="col-12 text-center" v-if="part==4">
                        <h3>Assessment Questions on English Proficiency</h3>

                        <form style="padding: 0px 25px;">

                            <div class="mt-5" data-aos="zoom-out-left" data-aos-duration="1000" data-aos-delay="300"
                                data-aos-once="true">
                                <h1 style="font-size: 18px;font-weight: normal; margin: 0px 0px 25px;">
                                    <span class="font-weight-bold">Reflective Essay:
                                    </span><br />
                                    Reflect on a significant event or experience in your life that has shaped who you
                                    are today. Discuss the impact of this event on your personal growth, values, and
                                    worldview. Consider how it has influenced your goals and aspirations for the future.
                                    Your response should be no less than 250 words.
                                </h1>
                                <textarea onpaste="return false;" spellcheck="false" class="form-control text-h p-3"
                                    placeholder="Your answer here" style="margin: 10px 0px;" v-model="test.number5"
                                    rows="18" cols="100%"></textarea>
                                <h4 class="text-right" v-if="words.number5<250">
                                    {{words.number5}}{{ words.number5 <= 1?' word' : ' words' }}</h4>
                                <h4 class="text-right success--text" v-else>
                                    {{words.number5}}{{ words.number5 <= 1?' word' : ' words' }}</h4>
                            </div>
                        </form>
                    </div>


                    <div class="col-12 text-center p-0">

                        <div class="btn-group btn-group-lg" role="group">
                            <button class="btn" type="button" v-if="ShowPrevious" @click="Previous()"
                                style="color: #FF5E21;background: #FFFFFF;font-family: Raleway, sans-serif;">Previous
                            </button>

                            <button class="btn" type="button" @click="Next()" v-if="showNext"
                                style="background: #FF5E21;color: white;font-family: Raleway, sans-serif;">{{btnNext}}
                            </button>

                            <button class="btn" type="submit" v-if="showSubmit"
                                style="background: #FF5E21;color: white;font-family: Raleway, sans-serif;">Submit
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            <!-- col 3 -->
            <div class="white col-12 col-lg-3 px-5 py-5" data-aos="slide-right" data-aos-duration="1500"
                data-aos-once="true">

                <div class="text-center">
                    <v-avatar size="200">
                        <img :src="require('@/images/umoja.jpeg')" class="img-fluid" />
                    </v-avatar>
                </div>

                <v-divider></v-divider>

                <div v-for="(x, k) in laListe" :key="k">
                    <h6 class="font-weight-bold">{{k+1 + '. ' + x.title}}: </h6>
                    <h6>{{ x.description }}</h6>
                    <v-divider></v-divider>
                </div>


            </div>

        </div>

        <span class="d-none">{{ countTheWords }}</span>
        <span class="d-none">{{ timeDown }}</span>

    </div>
</template>

<script>
    import firebase from "firebase";
    import db from "../db.js";

    export default {
        data() {
            return {
                laListe: [{
                        title: "Do not open additional tabs or windows",
                        description: "Ensure that only the test tab or window is open during the duration of the test."
                    },
                    {
                        title: "Do not use external resources",
                        description: "Refrain from using any external resources such as dictionaries, translation tools, or online reference materials."
                    },
                    {
                        title: "Do not communicate with others",
                        description: "Avoid any form of communication with classmates or others during the test, including messaging, calling, or sharing answers."
                    },
                    {
                        title: "Do not use unauthorized aids",
                        description: " Do not use any unauthorized aids such as notes, textbooks, or study guides unless explicitly permitted by the teacher."
                    },
                    {
                        title: "Do not attempt to cheat or plagiarize",
                        description: "Ensure that all responses are original and represent the student's own work. Avoid any form of cheating or plagiarism."
                    },
                    {
                        title: "Follow instructions carefully",
                        description: "Read and follow all instructions provided for each section of the test, including formatting requirements and time limits."
                    },
                    {
                        title: "Seek clarification if needed",
                        description: "If there are any uncertainties or questions regarding the test instructions, seek clarification from the teacher or test administrator before proceeding."
                    }

                ],

                toggle_exclusive: 0,
                test: {
                    number1: '',
                    number2: '',
                    number3: '',
                    number4: '',
                    number5: ''
                },
                words: {
                    number1: '',
                    number2: '',
                    number3: '',
                    number4: '',
                    number5: ''
                },


                part: 0,
                ShowPrevious: false,
                showNext: true,
                showSubmit: false,
                btnNext: "Next",
                txtOther: true,
                errorMessage: '',
                timeur: 3600,
                timeString: ''
            }
        },
        created() {

            this.$store.state.ye.email = this.getCookie("email");
            this.$store.state.ye.fullname = this.getCookie("fullname");
            this.$store.state.ye.phone = this.getCookie("phone");
            this.$store.state.ye.year_currently_in = this.getCookie("year_currently_in");
            this.$store.state.ye.graduation_year = this.getCookie("graduation_year");
            this.$store.state.ye.favorite_class = this.getCookie("favorite_class");

            if (this.$store.state.ye.email == '') {
                this.$router.push("/apply");
            }

            this.countdown();
            this.checkWindowOpener();
        },
        computed: {
            countTheWords() {
                return this.countWords();
            },
            timeDown() {
                return 0;
            }
        },
        methods: {
            setCookie(name, value, days) {
                const date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                const expires = "expires=" + date.toUTCString();
                document.cookie = name + "=" + value + ";" + expires + ";path=/";
            },
            getCookie(name) {
                const cookieName = name + "=";
                const decodedCookie = decodeURIComponent(document.cookie);
                const cookieArray = decodedCookie.split(';');
                for (let i = 0; i < cookieArray.length; i++) {
                    let cookie = cookieArray[i].trim();
                    if (cookie.indexOf(cookieName) === 0) {
                        return cookie.substring(cookieName.length, cookie.length);
                    }
                }
                return "";
            },
            countdown() {
                let interval = setInterval(() => {

                    // Decrement this.timeur by 1 second
                    this.timeur--;

                    // Convert this.timeur to hours, minutes, and seconds
                    let hours = Math.floor(this.timeur / 3600);
                    let minutes = Math.floor((this.timeur % 3600) / 60);
                    let seconds = this.timeur % 60;

                    // Format hours, minutes, and seconds as a string
                    this.timeString =
                        `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                    // Display the time string

                    // Check if this.timeur has reached 0
                    if (this.timeur <= 0) {
                        clearInterval(interval);
                        console.log("Time's up!");
                        this.sendData(null);
                    }
                }, 1000);

                console.log(this.timeString);
            },
            isEmptyOrSpaces(str) {
                str = str.toString();
                return str === null || str.trim() === '';
            },
            isValidEmail(email) {
                // Regular expression for validating an email address
                var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return emailRegex.test(email);
            },
            Next() {
                // var student = this.student;
                // var goNext = true;
                this.errorMessage = "";
                // alert("part = "+this.part);

                switch (this.part) {
                    case 0:
                        this.part = 1;
                        this.ShowPrevious = true;
                        break;
                    case 1:

                        this.part = 2;
                        this.ShowPrevious = true;
                        break;
                    case 2:

                        this.part = 3;
                        this.ShowPrevious = true;
                        break;
                    case 3:
                        this.part = 4;
                        this.ShowPrevious = true;
                        this.btnNext = "Submit";
                        break;
                    case 4:
                        // alert("Send data");
                        this.sendData(null);
                        this.showSubmit = false;
                        this.showNext = false;
                        break;
                }

                this.scrollUp();
            },
            Previous() {
                switch (this.part) {
                    case 4:
                        this.part = 3;
                        this.ShowPrevious = true;
                        this.showNext = true;
                        this.showSubmit = false;
                        this.btnNext = "Next";
                        break;
                    case 3:
                        this.part = 2;
                        this.ShowPrevious = true;
                        break;
                    case 2:
                        this.part = 1;
                        this.ShowPrevious = true;
                        break;
                    case 1:
                        this.part = 0;
                        this.ShowPrevious = true;
                        this.ShowPrevious = false;
                        break;

                    default:
                        break;
                }

                this.scrollUp();
            },
            pickCity(value) {
                switch (value) {
                    case "Iowa City":
                        this.txtOther = false;
                        break;
                    case "Coralville":
                        this.txtOther = false;
                        break;
                    case "North Liberty":
                        this.txtOther = false;
                        break;
                    case "Cedar Rapids":
                        this.txtOther = false;
                        break;
                    default:
                        this.txtOther = true;
                        break;
                }
            },
            sendData(value) {
                var that = this;
                try {
                    if(that.$store.state.ye.email!=""){
                        db.collection("quiz").add({
                        email: that.$store.state.ye.email,
                        fullname: that.$store.state.ye.fullname,
                        number1: that.test.number1,
                        number2: that.test.number2,
                        number3: that.test.number3,
                        number4: that.test.number4,
                        number5: that.test.number5,
                        score1: 0,
                        score2: 0,
                        score3: 0,
                        score4: 0,
                        score5: 0,
                        score: 0,
                        updatedAt: "",
                        par: "",
                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        year: new Date().getFullYear()
                    });

                    this.showSubmit = false;
                    this.setCookie("email", "", 1);

                    setTimeout(() => {
                        this.$store.state.dejavu = value;
                        this.$router.push("/startingTest");
                    }, 2000);

                    }
                } catch (error) {
                    alert(error);
                    that.errorMessage = error.message;
                    that.ShowPrevious = true;
                    that.showNext = false;
                    that.showSubmit = true;
                }
            },
            scrollUp() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth' // 'smooth' or 'auto'
                });
            },
            countWords() {

                var str;
                var words;

                // number 1;
                str = this.test.number1.trim();
                words = str.split(/\s+/);
                this.words.number1 = words.length;
                if (this.test.number1 == "") this.words.number1 = 0;

                // number 2;
                str = this.test.number2.trim();
                words = str.split(/\s+/);
                this.words.number2 = words.length;
                if (this.test.number2 == "") this.words.number2 = 0;

                // number 1;
                str = this.test.number3.trim();
                words = str.split(/\s+/);
                this.words.number3 = words.length;
                if (this.test.number3 == "") this.words.number3 = 0;

                // number 1;
                str = this.test.number4.trim();
                words = str.split(/\s+/);
                this.words.number4 = words.length;
                if (this.test.number4 == "") this.words.number4 = 0;

                // number 1;
                str = this.test.number5.trim();
                words = str.split(/\s+/);
                this.words.number5 = words.length;
                if (this.test.number5 == "") this.words.number5 = 0;

                return 0;
            },
            checkWindowOpener() {

                console.log("verify");
                var focused = true;

                window.addEventListener("focus", function () {
                    focused = true;
                });

                window.addEventListener("blur", function () {
                    focused = false;
                });

                let interval = setInterval(() => {
                if (!focused) {
                    // User is not on this tab
                    clearInterval(interval);
                    this.sendData("cheating");
                }
                }, 1000);
            },
            doIt(value) {
                if (this.part >= (value - 1)) {
                    this.part = value - 1;
                    this.btnNext = "Next";
                    this.Previous();
                } else {
                    if (value == 4) this.part = 3;
                    else this.part = value - 1;
                    if (value == 3) this.btnNext = "Submit";
                    this.Next();
                }
            }
        }
    }
</script>

<style>

</style>